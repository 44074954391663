<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <!-- <van-button type="danger">危险按钮</van-button> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "home",
  components: {},
  created() {
    this.$router.push({ path: "/mall/list" });
  }
};
</script>
