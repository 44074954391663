export function saveToken(token) {
  localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function goNavBack() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // 判断iPhone|iPad|iPod|iOS
    // window.onBack();
    window.webkit.messageHandlers.onBack.postMessage(null);
  } else if (/(Android)/i.test(navigator.userAgent)) {
    // 判断Android
    window.smart.onBack();
  }
}