<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <!-- <router-view /> -->

    <!-- <transition :name="transitionName">
      <router-view class="Router" v-if="!$route.meta.keepAlive"></router-view>
      <keep-alive>
        <router-view class="Router" v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>-->
    <!-- <router-view class="Router"></router-view> -->

    <transition :name="transitionName">
      <!-- <keep-alive :include="cachePage"> -->
        <router-view class="Router"></router-view>
      <!-- </keep-alive> -->
    </transition>
    <!-- <transition :name="transitionName">
      <router-view class="Router"></router-view>
    </transition> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      cachePage: "ProductList, Settlement, Orders, OrderDetail", // 缓存的页面名称
      transitionName: "slide-right" // 默认动态路由变化为slide-right
    };
  },
  created() {
    // window.console.log('this.$route.query =', this.$route.query);
    // window.console.log('this.$route.query =', this.$route.query);
    // this.$router.push({ path: "/statistics", query: {id : 1} });

    window.onload = function() {
      document.addEventListener("touchstart", function(event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function(event) {
        event.preventDefault();
      });
    };
  },
  watch: {
    $route(to, from) {
      const isBack = this.$router.isBack; //  监听路由变化时的状态为前进还是后退
      if (isBack) {
        this.transitionName = "slide-right";
      } else {
        this.transitionName = "slide-left";
      }
      this.$router.isBack = false;
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100%;
}
.page {
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}

.Router {
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  // top: 40px;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100% 0);
}
.select-rigth {
  position: absolute;
  top: 0%;
  right: 10px;
  display: flex;
  height: 100%;
  // width: 30px;
  justify-content: center;
  align-items: center;
}
.icon-right {
  background-position: center center;
  background-image: url("assets/right.png");
  height: 20px;
  width: 20px;
  display: inline-block;
}
.maginTop {
  padding-top: 46px;
}
</style>
