/* eslint-disable comma-dangle */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { saveToken } from "../common/utils.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/article",
    name: "article",
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/Article.vue")
  },
  {
    path: "/article/:id",
    name: "articleDetail",
    component: () =>
      import(/* webpackChunkName: "articleDetail" */ "../views/ArticleDetail.vue")
  },
  {
    path: "/statistics",
    name: "statistics",
    meta: { title: "统计", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "articleDetail" */ "../views/Statistics.vue")
  },
  {
    path: "/register/:code",
    name: "register",
    meta: { title: "用户注册", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "articleDetail" */ "../views/Register.vue")
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/download",
    name: "download",
    meta: { title: "应用下载", keepAlive: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Download.vue")
  },
  {
    path: "/mall/list",
    name: "ProductList",
    meta: { title: "商城首页", keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "productList" */ "../views/mall/ProductList.vue")
  },
  {
    path: "/mall/detail/:id",
    name: "ProductDetail",
    meta: { title: "商城详情", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "productDetail" */ "../views/mall/ProductDetail.vue")
  },
  {
    path: "/mall/settlement",
    name: "Settlement",
    meta: { title: "商城首页", keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "settlement" */ "../views/mall/Settlement.vue")
  },
  {
    path: "/mall/orders",
    name: "orders",
    meta: { title: "订单", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/mall/Orders.vue")
  },
  {
    path: "/mall/addressList",
    name: "addressList",
    meta: { title: "收货地址", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "address" */ "../views/mall/AddressList.vue")
  },
  {
    path: "/mall/orderDetail",
    name: "orderDetail",
    meta: { title: "订单详情", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "orderDetail" */ "../views/mall/OrderDetail.vue")
  },
  {
    path: "/mall/addressEdit",
    name: "addressEdit",
    meta: { title: "收货地址", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "addressEdit" */ "../views/mall/AddressEdit.vue")
  },
  {
    path: "/mall/orderStatus",
    name: "orderStatus",
    meta: { title: "订单状态", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "addressEdit" */ "../views/mall/OrderStatus.vue")
  },
  {
    path: "/allData",
    name: "allData",
    meta: { title: "所有数据", keepAlive: false },
    component: () =>
        import(/* webpackChunkName: "articleDetail" */ "../views/AllData.vue")
  },
];

const errorRoute = [
  {
    path: "/401",
    name: "page401",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/error/Page401.vue")
  },
  {
    path: "/500",
    name: "page500",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/error/Page500.vue")
  },
  {
    path: "*",
    name: "page404",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/error/Page404.vue")
  },
  {
    path: "/digital",
    name: "digital",
    meta: { title: "银行卡申请", keepAlive: false },
    component: () =>
        import(/* webpackChunkName: "articleDetail" */ "../views/Digital.vue")
  },
];

for (const item of errorRoute) {
  routes.push(item);
}
VueRouter.prototype.goBack = function() {
  this.isBack = true;
  window.history.go(-1);
  // VueRouter.prototype.back()
};

VueRouter.prototype.goMallHome = function() {
  this.isBack = true;
  router.push({ path: "/mall/list" });
  // window.location.href = "/#/mall/list";
};

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  // window.console.log('query = ', to.query)
  const query = to.query;
  if (query.token) {
    // window.console.log('token = ', query.token);
    saveToken(query.token);
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
