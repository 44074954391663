/* eslint-disable no-multiple-empty-lines */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Button,
  Tab,
  Tabs,
  Field,
  CellGroup,
  Cell,
  NumberKeyboard,
  Toast,
  Tag,
  List,
  Col,
  Row,
  NavBar,
  AddressList,
  AddressEdit,
  Dialog,
  Popup,
  PasswordInput,
  Loading,
  DatetimePicker,
  Picker,
  Icon
} from "vant";
// import VueAxios from 'vue-axios';
import ECharts from "vue-echarts";
// 手动引入 ECharts 各模块来减小打包体积
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";

Vue.config.productionTip = false;

// 导入的vant的组件
const vant = [
  Button,
  Tab,
  Tabs,
  Field,
  CellGroup,
  Cell,
  NumberKeyboard,
  Toast,
  Tag,
  List,
  Col,
  Row,
  NavBar,
  AddressList,
  AddressEdit,
  Dialog,
  Popup,
  PasswordInput,
  Loading,
  DatetimePicker,
  Picker,
  Icon
];
for (const item of vant) {
  Vue.use(item);
}

// Vue.use(VueAxios, axios);

Vue.component("v-chart", ECharts);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
